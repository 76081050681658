export const API_BASE_URL = "https://api.indiaeats.com";
export const MEDIA_URL = "Xyz";
export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/admin/auth/login`,
    me: `${API_BASE_URL}/api/admin/auth/me`,
    change_password: `${API_BASE_URL}/admin/auth/change-password`,
    forgotPassword: `${API_BASE_URL}/api/admin/auth/forgot-password`,
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/settings/dashboard`,
  },
  USERROLE: {
    create: `${API_BASE_URL}/api/admin/userole`,
    list: `${API_BASE_URL}/api/admin/userole/get-all-user`,
    edit: (id) => `${API_BASE_URL}/api/admin/userole/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/userole/remove/${id}`,
  },
  Restaurant: {
    list: `${API_BASE_URL}/api/admin/restaurant/get-all-restaurant`,
    create: `${API_BASE_URL}/api/admin/restaurant`,
    get: (id) => `${API_BASE_URL}/api/admin/restaurant/${id}`,
    edit: (id) => `${API_BASE_URL}/api/admin/restaurant/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/restaurant/remove/${id}`,
    delete_category: (id) =>
      `${API_BASE_URL}/api/admin/restaurant/restaurant-catagory/${id}`,
    update_status: (id) => `${API_BASE_URL}/api/admin/restaurant/${id}`,
  },
  Restaurant_Menu: {
    list: `${API_BASE_URL}/api/admin/menu/get-all-menu`,
    create: `${API_BASE_URL}/api/admin/menu`,
    edit: (id) => `${API_BASE_URL}/api/admin/menu/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/menu/remove/${id}`,
  },
  Restaurant_Tags: {
    list: `${API_BASE_URL}/api/admin/restaurant-tag/get-all-restaurant-tag`,
    dropdown_list: `${API_BASE_URL}/api/admin/restaurant-tag/get-all-restaurant-tag-dropdown`,
    add: `${API_BASE_URL}/api/admin/restaurant-tag`,
    edit: (id) => `${API_BASE_URL}/api/admin/restaurant-tag/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/restaurant-tag/remove/${id}`,
  },
  Category: {
    list: `${API_BASE_URL}/api/admin/menu-catagory/get-all-menu-catagory`,
    create: `${API_BASE_URL}/api/admin/menu-catagory`,
    get: (id) => `${API_BASE_URL}/api/admin/menu-catagory/${id}`,
    edit: (id) => `${API_BASE_URL}/api/admin/menu-catagory/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/menu-catagory/remove/${id}`,
  },
  Category_Menu: {
    list: `${API_BASE_URL}/api/admin/menu/get-all-menu`,
    create: `${API_BASE_URL}/api/admin/menu`,
    get: (id) => `${API_BASE_URL}/api/admin/menu/category/${id}`,
    edit: (id) => `${API_BASE_URL}/api/admin/menu/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/menu/remove/${id}`,
  },
  Customize_Menu_Category: {
    list: (id) => `${API_BASE_URL}/api/admin/customize-menu/${id}`,
    create: `${API_BASE_URL}/api/admin/customize-menu`,
    edit: (id) => `${API_BASE_URL}/api/admin/customize-menu/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/customize-menu/${id}`,
  },
  Contact_Us: {
    list: `${API_BASE_URL}/api/admin/contact-us`,
    create: `${API_BASE_URL}/api/admin/contact-us`,
    edit: (id) => `${API_BASE_URL}/api/admin/contact-us/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/contact-us/remove/${id}`,
  },
  Delivery_Fees: {
    list: `${API_BASE_URL}/api/admin/delivry-fees`,
    create: `${API_BASE_URL}/api/admin/delivry-fees`,
    edit: (id) => `${API_BASE_URL}/api/admin/delivry-fees/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/delivry-fees/remove/${id}`,
  },
  Gst_Amount: {
    list: `${API_BASE_URL}/api/admin/gst`,
    create: `${API_BASE_URL}/api/admin/gst`,
    edit: (id) => `${API_BASE_URL}/api/admin/gst/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/gst/remove/${id}`,
  },
  Delivery_Range: {
    list: `${API_BASE_URL}/api/admin/delivry-range`,
    create: `${API_BASE_URL}/api/admin/delivry-range`,
    edit: (id) => `${API_BASE_URL}/api/admin/delivry-range/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/delivry-range/remove/${id}`,
  },
  Privacy_Policy: {
    list: `${API_BASE_URL}/api/admin/privacy-policy`,
    create: `${API_BASE_URL}/api/admin/privacy-policy`,
    edit: (id) => `${API_BASE_URL}/api/admin/privacy-policy/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/privacy-policy/remove/${id}`,
  },
  Return_Refund: {
    list: `${API_BASE_URL}/api/admin/return-fefund`,
    create: `${API_BASE_URL}/api/admin/return-fefund`,
    edit: (id) => `${API_BASE_URL}/api/admin/return-fefund/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/return-fefund/remove/${id}`,
  },
  Terms_Services: {
    list: `${API_BASE_URL}/api/admin/terms-service`,
    create: `${API_BASE_URL}/api/admin/terms-service`,
    edit: (id) => `${API_BASE_URL}/api/admin/terms-service/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/terms-service/remove/${id}`,
  },
  Delivery_Boys: {
    list: `${API_BASE_URL}/api/admin/delivery-boy`,
    get: (id) => `${API_BASE_URL}/api/admin/delivery-boy/${id}`,
    update: (id) => `${API_BASE_URL}/api/admin/delivery-boy/${id}`,
    register: `${API_BASE_URL}/api/delivry-boy/auth/register`,
    create: `${API_BASE_URL}/api/delivry-boy/auth/register`,
    verifyOtp: `${API_BASE_URL}/api/delivry-boy/auth/verify-otp`,
    uploadDocs: `${API_BASE_URL}/api/delivry-boy/auth/upload-document`,
    delete: (id) => `${API_BASE_URL}/api/delivry-boy/auth/remove/${id}`,
  },
  Slider: {
    addSlider: `${API_BASE_URL}/api/admin/slider`,
    get: `${API_BASE_URL}/api/admin/slider`,
    edit: (id) => `${API_BASE_URL}/api/admin/slider/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/slider/remove/${id}`,
  },
  Order: {
    list: `${API_BASE_URL}/api/admin/order`,
    get: (id) => `${API_BASE_URL}/api/admin/order/${id}`,
    update_status: (id) => `${API_BASE_URL}/api/admin/order/${id}`,
  },
  PromoCode: {
    add: `${API_BASE_URL}/api/admin/promo-code`,
    edit: (id) => `${API_BASE_URL}/api/admin/promo-code/${id}`,
    list: `${API_BASE_URL}/api/admin/promo-code`,
    delete: (id) => `${API_BASE_URL}/api/admin/promo-code/remove/${id}`,
    get: (id) => `${API_BASE_URL}/api/admin/promo-code/${id}`,
  },
  Feedback_Ratings: {
    list: `${API_BASE_URL}/api/admin/feed-back-rating`,
    delete: (id) => `${API_BASE_URL}/api/admin/feed-back-rating/remove/${id}`,
    get: (id) => `${API_BASE_URL}/api/admin/feed-back-rating/${id}`,
    update_status: (id) => `${API_BASE_URL}/api/admin/feed-back-rating/${id}`,
    customer_update_status: (id) =>
      `${API_BASE_URL}/api/admin/feed-back-rating/customer/${id}`,
    restuarant_update_status: (id) =>
      `${API_BASE_URL}/api/admin/feed-back-rating/restaurant/${id}`,
    deliveryboy_update_status: (id) =>
      `${API_BASE_URL}/api/admin/feed-back-rating/delivery-boy/${id}`,
    menuItem_update_status: (id) =>
      `${API_BASE_URL}/api/admin/feed-back-rating/menu/${id}`,
  },
  Vendor_Support: {
    list: `${API_BASE_URL}/api/admin/vendor-support`,
    get: (id) => `${API_BASE_URL}/api/admin/vendor-support/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/vendor-support/remove/${id}`,
    update_status: (id) => `${API_BASE_URL}/api/admin/vendor-support/${id}`,
  },
  Customer_Support: {
    list: `${API_BASE_URL}/api/admin/customer-support`,
    get: (id) => `${API_BASE_URL}/api/admin/customer-support/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/customer-support/remove/${id}`,
    update_status: (id) => `${API_BASE_URL}/api/admin/customer-support/${id}`,
    reply: `${API_BASE_URL}/api/admin/customer-support/reply`,
  },
  Notification: {
    add: `${API_BASE_URL}/api/admin/notification`,
    edit: (id) => `${API_BASE_URL}/api/admin/notification/${id}`,
    list: `${API_BASE_URL}/api/admin/notification`,
    customer_get: `${API_BASE_URL}/api/admin/notification/customer-list`,
    delete: (id) => `${API_BASE_URL}/api/admin/notification/remove/${id}`,
    get: (id) => `${API_BASE_URL}/api/admin/notification/${id}`,
  },
  Reports: {
    list: `${API_BASE_URL}/api/admin/report`,
  },
  GetInTouch: {
    list: `${API_BASE_URL}/api/common/customer-get-in-touch`,
  },
  Packaging_Fees: {
    list: `${API_BASE_URL}/api/admin/packaging-fees`,
    create: `${API_BASE_URL}/api/admin/packaging-fees`,
    edit: (id) => `${API_BASE_URL}/api/admin/packaging-fees/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/packaging-fees/remove/${id}`,
  },
};
